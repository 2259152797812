/* mobile styles */
@media only screen and (max-width: 767px) {
	.t1 {
		table-layout: auto;
	}
	.t2 {
		table-layout: auto;
	}
}
/* desktop styles */
@media only screen and (min-width: 768px) {
	.t1 {
		table-layout: fixed;
		width: 100%;
	}
	.t2 {
		table-layout: fixed;
		width: 100%;
	}
}
