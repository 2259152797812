adaptativeTbody {
	height: 50%;
	width: 100%;
	overflow: auto;
	display: block;
}

#criterio {
	width: 100%;
	height: 100%;
}

.clickable {
	cursor: pointer;
}

.hover-div {
	transition: background-color 0.3s;
}

.hover-div:hover {
	background-color: #e8eaed;
}

.navbarS {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1001;
	overflow: visible;
	height: 120px;
}

.sepA {
	height: 120px;
	background-color: #d0fdf9;
}

.modalCup {
	position: absolute;
	z-index: 1005;
	width: 100%;
	height: 100%;
	padding-top: 5%;
	border: rgba(0, 0, 0, 0.479) solid 1px;
	background: rgba(0, 0, 0, 0.479) !important;
	display: none;
}

.modal-fixed {
	position: fixed;
	left: 0;
	right: 0;
}

.modal-content {
	width: 75% !important;
	background-color: #0b0a0a;
	border: #888 solid 1px;
	margin: auto;
	top: 13%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	animation-name: animatetop;
	animation-duration: 0.5s;
}

#imgRender {
	width: 105%;
	height: 100%;
}

.botonCerrar {
	color: black;
	width: 2%;
	height: 100%;
	float: right;
}

.cpn {
	height: 5%;
	overflow-y: hidden;
}

.modal-header {
	background-color: #0dcbca;
	color: white;
	margin-bottom: 1%;
	height: 10%;
}

.modal-body {
	color: rgb(65, 58, 58);
	padding: 2%;
	height: 80%;
	overflow-y: hidden;
	overflow-x: hidden;

	background-color: #fff;
}

.modal-footer {
	padding: 2px 16px;
	background-color: #0dcbca;
	color: white;
	top: 85%;
	height: 10%;
}

.titleMod {
	left: 1px;
	color: #0d0e0f;
}

@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}

.btnL {
	color: rgb(82, 81, 81);
}
.btnL:hover {
	color: black;
}

.close:hover {
	color: red;
}

.tiktok {
	font-size: 0px;
}

.card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ContainerA {
	display: flex;
	width: 100%;
	height: auto;
	justify-content: space-evenly;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}

.icon {
	align-self: center;
	width: 100%;
	height: 80px;
	margin-top: 15%;
	color: rgb(30, 48, 80);
}
.btnN {
	display: flex row;
	cursor: pointer;
	width: 175px;
	height: 175px;
	font-weight: 400;
	text-align: center;
	margin: 1% 0.1%;
	vertical-align: center;
	-webkit-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (max-width: 1220px) {
	.btnN {
		width: 185px;
		height: 185px;
		font-size: 1.3rem;
		margin: 1% 0.1%;
	}
	.icon {
		width: 100%;
		height: 80px;
	}
}
@media screen and (max-width: 1199px) {
	.btnN {
		width: 155px;
		height: 175px;
		font-size: 1.1rem;
		margin: 1% 0.2%;
	}
	.icon {
		width: 100%;
		height: 80px;
	}
}

@media screen and (max-width: 990px) {
	.btnN {
		width: 245px;
		height: 245px;
		font-size: 1.2rem;
		margin: 1% 0.3%;
	}
	.icon {
		width: 100%;
		height: 120px;
	}
}

@media screen and (max-width: 765px) {
	.btnN {
		width: 160px;
		height: 160px;
		font-size: 0.8rem;
	}
	.icon {
		width: 100%;
		height: 80px;
	}
}

@media screen and (max-width: 425px) {
	.btnN {
		width: 135px;
		height: 150px;
		font-size: 0.9rem;
		margin: 1% 0.1%;
	}
	.icon {
		width: 70%;
		height: 60px;
	}
}
@media screen and (max-width: 360px) {
	.btnN {
		width: 115px;
		height: 130px;
		font-size: 0.8rem;
	}
	.icon {
		width: 70%;
		height: 60px;
	}
}

@media screen and (max-width: 355px) {
	.btnN {
		width: 95px;
		height: 130px;
		font-size: 0.8rem;
	}
	.icon {
		width: 70%;
		height: 60px;
	}
}
@media screen and (max-width: 280px) {
	.btnN {
		width: 85px;
		height: 130px;
		font-size: 0.7rem;
	}
	.icon {
		width: 70%;
		height: 60px;
	}
}

.btn-red {
	background-color: #cb4d4d;
	color: #000000;
}

.btn-red:hover {
	background-color: rgba(168, 64, 64, 0.645);
}

.btn-gold {
	background-color: #c6ad59;
	color: #000000;
}
.btn-gold:hover {
	background-color: #c6ac59b0;
}

.btn-yellow {
	background-color: #dcbe52;
	color: #000000;
}
.btn-yellow:hover {
	background-color: #edcd5aaf;
}

.btn-lemon {
	background-color: #9dd75f;
	color: #000000;
}
.btn-lemon:hover {
	background-color: #b5f471bb;
}

.btn-green {
	background-color: #81d140;
	color: #000000;
}
.btn-green:hover {
	background-color: #81d140bb;
}

.btn-redora {
	background-color: #eda15f;
	color: #000000;
}
.btn-redora:hover {
	background-color: #eda15fa6;
}

.btn-hotPink {
	background-color: #f374b4f9;
	color: #000000;
}
.btn-hotPink:hover {
	background-color: #ff69b4b0;
	color: #000000;
}

.btn-violet {
	background-color: #a182e5;
	color: #000000;
}
.btn-violet:hover {
	background-color: #a182e5ac;
	color: #000000;
}

.btn-seaGreen {
	background-color: #29d5efe2;
	color: #000000;
}
.btn-seaGreen:hover {
	background-color: #29d5efac;
}

.btn-cyan {
	background-color: #048edef6;
	color: #000000;
}
.btn-cyan:hover {
	background-color: #0487dea7;
}
.btn-newOrange {
	background-color: #edc951;
}
.btn-newOrange:hover {
	background-color: #e0bd4b;
}

.letraAlerta {
	color: white;
}

.alerta {
	display: flex;
	flex-wrap: wrap;
	float: right;
	width: 207px;
	height: 60px;
	background-color: #366169b4;
	border-top-left-radius: 10px 10px;
	border-top-right-radius: 10px 10px;
	border-bottom-right-radius: 10px 10px;
	border-bottom-left-radius: 10px 10px;
	padding: 10px;
	margin-top: 15px;
	margin-right: 20px;
}

.alertaVidas {
	display: flex;
	flex-wrap: wrap;
	float: right;
	width: 348px;
	height: 38px;
	background-color: #366169b4;
	border-top-left-radius: 6px 6px;
	border-top-right-radius: 6px 6px;
	border-bottom-right-radius: 6px 6px;
	border-bottom-left-radius: 6px 6px;
	padding: 10px;
	margin-top: -0px;
	margin-right: -340px;
}

.alertaNum {
	display: flex;
	flex-wrap: wrap;
	float: right;
	width: 265px;
	height: 38px;
	background-color: #366169b4;
	border-top-left-radius: 6px 6px;
	border-top-right-radius: 6px 6px;
	border-bottom-right-radius: 6px 6px;
	border-bottom-left-radius: 6px 6px;
	padding: 10px;
	margin-top: -0px;
	margin-right: -260px;
}

@media (min-width: 280px) and (max-width: 450px) {
	.alerta {
		float: left;
		width: 200px;
		height: 60px;
		padding: 5px;
		margin-top: 10px;
		margin-left: -155px;
	}

	.alertaVidas {
		float: left;
		width: 200px;
		height: 50px;
		padding: 5px;
		margin-top: -50px;
		margin-left: -200px;
	}

	.alertaNum {
		float: left;
		width: 200px;
		height: 50px;
		padding: 5px;
		margin-top: -50px;
		margin-left: -200px;
	}
}

@media (min-width: 451px) and (max-width: 740px) {
	.alerta {
		float: right;
		width: 207px;
		height: 60px;
		padding: 10px;
		margin-top: 15px;
		margin-right: -50px;
	}

	.alertaVidas {
		float: right;
		width: 200px;
		height: 60px;
		padding: 10px;
		margin-top: -60px;
		margin-right: 100px;
	}

	.alertaNum {
		float: right;
		width: 265px;
		height: 38px;
		padding: 10px;
		margin-top: -38px;
		margin-right: 100px;
	}
}
@media (min-width: 741px) and (max-width: 1300px) {
	.alerta {
		float: right;
		width: 207px;
		height: 60px;
		padding: 10px;
		margin-top: 15px;
		margin-right: -50px;
	}

	.alertaVidas {
		float: right;
		width: 348px;
		height: 38px;
		padding: 10px;
		margin-top: -38px;
		margin-right: 100px;
	}

	.alertaNum {
		float: right;
		width: 265px;
		height: 38px;
		padding: 10px;
		margin-top: -38px;
		margin-right: 100px;
	}
}

@media (min-width: 2000px) and (max-width: 3000px) {
	.alerta {
		float: right;
		width: 207px;
		height: 60px;
		padding: 10px;
		padding: 10px;
		margin-top: 35px;
		margin-right: 150px;
	}

	.alertaVidas {
		float: right;
		width: 348px;
		height: 38px;
		padding: 10px;
		margin-top: 0px;
		margin-right: -290px;
	}

	.alertaNum {
		float: right;
		width: 265px;
		height: 38px;
		padding: 10px;
		margin-top: 0px;
		margin-right: -200px;
	}
}

@media (min-width: 3001px) and (max-width: 4000px) {
	.alerta {
		float: right;
		width: 207px;
		height: 60px;
		padding: 10px;
		margin-top: 35px;
		margin-right: 300px;
	}

	.alertaVidas {
		float: right;
		width: 348px;
		height: 38px;
		padding: 10px;
		margin-top: 0px;
		margin-right: -200px;
	}

	.alertaNum {
		float: right;
		width: 265px;
		height: 38px;
		padding: 10px;
		margin-top: 0px;
		margin-right: -120px;
	}
}

.skeletonloadbox {
	position: relative;
	width: 100%;
	height: auto;
}

.skeletonloadbox .shadowLayer {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto 0;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: auto;
	min-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: center;
	border-radius: 4px;
}

/*TOOLTIP LOCAL*/

.tooltiplocal {
	position: relative;
	cursor: pointer;
}

span {
	font-weight: 500;
	position: absolute;
	white-space: nowrap;
	transition: 0.3s;
}

.tooltiplocal:hover span {
	visibility: visible;
	opacity: 1;
	padding: 7px 12px;
	background-color: #57606f;
	border-radius: 6px;
	box-shadow: 10px 0 50px rgba(0, 0, 0, 0.5);
}

@keyframes Rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.spinner {
	width: 40px;
	height: 40px;
	border: 4px solid lightgray;
	border-top-color: #2ecc71;
	animation: Rotate 1.2s linear 0s infinite forwards;
}

.marrig-1 {
	margin-right: -22px;
}

.tool {
	width: 50%;
}

.tooledit {
	background-color: rgba(0, 0, 0, 0.555);
}
