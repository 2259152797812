.logo {
	background-color: #b8fff9;
}

.footer {
	background-color: #b8fff9;
	opacity: 0.8;
}

.title-footer {
	color: #42c2ff;
}

.main {
	background-color: #effffd;
	overflow: hidden;
}

.titleText {
	color: #42c2ff;
}

.color-box {
	color: #42c2ff;
}

.business-card {
	background-color: white;
	border-radius: 15px;
	height: 180px;
	max-width: 220px;
	cursor: pointer;
	margin: left 5px right 5px;
	margin-top: 45px;
	overflow: hidden;
	padding: 9px 0px;
}

.business-img {
	border-radius: 15px;
	width: 90%;
	height: 120px;
	display: block;
	margin: 0 auto;
	object-fit: contain;
}

.menu-container {
	position: relative;
	z-index: 1;
	-webkit-user-select: none;
	user-select: none;
	box-sizing: border-box;
}

.menu-container input {
	display: block;
	width: 35px;
	height: 25px;
	margin: 0;
	position: absolute;
	cursor: pointer;
	opacity: 0;
	z-index: 2;
	-webkit-touch-callout: none;
}

.menu-container button {
	display: block;
	width: 33px;
	height: 4px;
	margin-bottom: 5px;
	position: relative;
	background: #e52d2f;
	border-radius: 3px;
	z-index: 1;
	transform-origin: 4px 0px;
	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
		background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.menu-container button:first-child {
	transform-origin: 0% 0%;
}

.menu-container button:nth-child(3) {
	transform-origin: 0% 100%;
}

.menu-container input:checked ~ button {
	opacity: 1;
	transform: rotate(50deg) translate(3px, -1px);
	background: #232323;
}

.menu-container input:checked ~ button:nth-child(4) {
	opacity: 0;
	transform: rotate(0deg) scale(0.2, 0.2);
}

.menu-container input:checked ~ button:nth-child(3) {
	transform: rotate(-50deg) translate(-5px, 11px);
}

@media only screen and (max-width: 767px) {
	.menu-container {
		flex-direction: column;
	}

	.menu {
		position: absolute;
		box-sizing: border-box;
		width: 100%;
		right: -100%;
		top: 0;
		background: #effffd;
		-webkit-font-smoothing: antialiased;
		/* to stop flickering of text in safari */
		transform-origin: 0% 0%;
		transform: translateX(0%);
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
		border-radius: 15px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	}

	.menu-container input:checked ~ .menu {
		transform: translateX(-100%);
		position: relative;
	}

	.business-card {
		margin-top: 45px;
	}
}
@media only screen and (min-width: 768px) {
	.menu-container {
		width: 100%;
	}
	.menu-container input {
		display: none;
	}

	.menu-container button {
		display: none;
	}

	.menu {
		position: relative;
		width: 100%;
		justify-content: space-between;
	}

	.menu ul {
		display: flex;
		padding: 0;
	}

	.menu li {
		padding: 0 20px;
	}

	.business-card {
		margin-top: 45px;
	}
}
