@media (min-width: 280px) and (max-width: 575px) {
	.mapCaja {
		height: 300px;
	}
	.api {
		margin-top: 10%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.mapCaja {
		height: 460px;
	}
	.api {
		margin-top: 10%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.mapCaja {
		height: 460px;
	}
	.api {
		margin-top: 10%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media (min-width: 992px) and (max-width: 1999px) {
	.mapCaja {
		height: 460px;
	}
	.api {
		margin-top: 7%;
	}
}

@media (min-width: 1200px) {
	.mapCaja {
		height: 460px;
	}
	.api {
		margin-top: 0%;
	}
}

.smallText {
	font-size: 70%;
	height: 15%;
	overflow-y: hidden;
	overflow-x: hidden;
	overflow: hidden;
}

.social {
	height: 650px !important;
	width: 100%;
	overflow-y: auto;
	overflow-x: auto;
}
