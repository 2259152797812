.logo {
	background-color: #d0fdf9;
}

.footer {
	background-color: #cef5f2;
}

.title-footerr {
	color: #42c2ff;
}

.main {
	background-color: #effffd;
	width: 99%;
}

.titleText {
	color: #42c2ff;
}

.color-box {
	color: #42c2ff;
}

.supaMap {
	height: 500px;
}

.gridbusiness {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

@media (min-width: 700px) and (orientation: landscape) {
	.itemgrid {
		display: block;
		width: 210px;
	}
}

@media (max-width: 500px) {
	.gridbusiness {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	.itemgrid {
		width: 100%;
	}
}
