#buttonHeader {
	align-items: center;
	appearance: none;
	background-image: radial-gradient(
		100% 100% at 100% 0,
		#5adaff 0,
		#5468ff 100%
	);
	border: 0;
	border-radius: 6px;
	box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
		rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-flex;
	font-family: 'JetBrains Mono', monospace;
	height: 48px;
	justify-content: center;
	line-height: 1;
	list-style: none;
	overflow: hidden;
	padding-left: 16px;
	padding-right: 16px;
	position: relative;
	text-align: left;
	text-decoration: none;
	transition: box-shadow 0.15s, transform 0.15s;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	will-change: box-shadow, transform;
	font-size: 18px;
}
#charts {
	background-color: gainsboro;
	display: flex; /* Activamos el modo flexbox */
	justify-content: center; /* Centramos horizontalmente */
	align-items: center; /* Centramos verticalmente */
}
#businessName {
	text-align: left;
	padding-left: 20px;
	border-radius: 6px;
	font-family: 'Times New Roman', Times, serif;
	font-size-adjust: 0.5;
	font-size: 25px;
	background-color: white;
	color: black;
}

#list-right {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
}
#list-right ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

#list-right li {
	margin-left: 1rem;
	padding: 8px;
	margin-bottom: 7px;
	background-color: #33b5e5;
	color: #ffffff;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
#list-right li:hover {
	background-color: #0099cc;
}

@media (max-width: 768px) {
	#businessName {
		font-size: 0.9rem;
	}
}

@media (max-width: 480px) {
	#businessName {
		font-size: 0.8rem;
	}
}
