body {
	/*background: url(https://images2.alphacoders.com/941/thumb-1920-941898.jpg) no-repeat center center fixed;*/
	background: url('./resources/images/PapelTapizCuponealo.png') no-repeat center
		center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.container-client {
	min-height: 100px;
	min-width: 400px;
	align-content: center;
	display: flex;
	align-items: stretch;
}

.container-bd {
	display: flex;
	align-items: stretch;
	align-content: center;
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-300px * 7));
	}
}

.slider {
	background: transparent;
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
	height: 380px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.slide-track {
	animation: scroll 120s linear infinite;
	display: flex;
	width: calc(300px * 21);
}

.slide {
	height: 325px;
	width: 250px;
	border-radius: 10px;
}

.subtitleText {
	text-align: center;
	font-family: 'Eras Medium ITC', serif;
	font-size: x-large;
}
.titleText {
	text-align: center;
	font-family: 'Berlin Sans FB', serif;
	font-size: xx-large;
}

p {
	font-family: sans-serif;
	font-size: 13px;
	color: #646566;
	line-height: 1.5em;
}

strong {
	color: #fff;
	font-weight: 700;
	font-size: 60px;
	line-height: 100px;
}
